<template>
  <div class="bg-white rounded-b-md rounded-tr-md pt-4">
    <table class="table-auto w-full">
      <thead class="text-left text-sm text-lightBlack bg-oliveGreen">
        <tr class="border-t border-b border-[#e7e6e6]">
          <th class="py-2 pl-[5.25rem]">
            {{ $t('assetManagement.objectGroups-name') }}
          </th>
          <th class="py-2">
            {{ $t('assetManagement.objectGroups-id') }}
          </th>
          <th class="py-2">
            {{ $t('assetManagement.objectGroups-organisation') }}
          </th>
          <th class="py-2 text-center" v-show="showScore">
            {{ $t('assetManagement.objectGroups-score') }}
          </th>
          <th class="text-center py-2 pr-11">
            {{ $t('assetManagement.objectGroups-actions') }}
          </th>
        </tr>
      </thead>

      <tbody class="text-lightBlack">
        <template v-if="groupsPage.length > 0">
          <ObjectGroupRow v-for="(group, idx) in groupsPage" :key="idx" :group="group" :showDashboard="true" />
          <tr v-for="idx in numPlaceholders" :key="idx" class="item-row"></tr>
        </template>

        <tr v-else>
          <td class="text-center font-medium py-16" colspan="4">
            {{
              pending
              ? `${$t('general.loading')} ...`
              : error
                ? `${$t('table.loadError')}.`
                : `${$t('table.empty')}.`
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ObjectGroupRow } from '@/components/dataTable'
import { useListFetch } from '@/composition'
import { general } from '@/helpers'

export default {
  components: {
    ObjectGroupRow,
  },
  setup() {
    const store = useStore()
    const errorMessage = () =>
      store.dispatch('toastMessage/showMessage', {
        type: 'error',
        translationKey: 'objectGroups-list-fetch-error',
      })
    return useListFetch('objectGroups', errorMessage)
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    pageSlice: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: ['update:table'],
  data() {
    return {
      selectedGroup: null,
    }
  },
  inject: [],
  computed: {
    numPlaceholders() {
      if (
        this.totalItems <= this.pageSize ||
        this.groupsPage.length === this.pageSize
      ) {
        return 0
      }
      return this.pageSize - (this.groupsPage.length % this.pageSize)
    },
    searchTerm() {
      return this.search.trim().toLowerCase()
    },
    searchedGroups() {
      const searchKeys = ['id', 'name', 'organisationName']
      return general.searchOverKeys(this.list, searchKeys, this.searchTerm)
    },
    groupsPage() {
      return this.searchedGroups.slice(...this.pageSlice)
    },
  },
  methods: {
    
  },
  watch: {
    'searchedGroups'(newList) {
      this.$emit('update:table', newList.length)
    },
    'newGroup.value'(group) {
      this.list.push(group)
    },
    'newObject.value'(object) {
      this.list
        .find(group => group.id === object.assetGroup.id)
        .assets.push(object)
    },
  },
}
</script>

<style scoped>
.item-row {
  @apply border-lighterGray border-b;
}
</style>
